import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Часто задаваемые вопросы в мастерской АвтоПульс
			</title>
			<meta name={"description"} content={"Запросы под капотом - управление вашими познаниями в Автопульсе"} />
			<meta property={"og:title"} content={"FAQ | Часто задаваемые вопросы в мастерской АвтоПульс"} />
			<meta property={"og:description"} content={"Запросы под капотом - управление вашими познаниями в Автопульсе"} />
			<meta property={"og:image"} content={"https://xjuxly.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://xjuxly.com/img/34235130.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://xjuxly.com/img/34235130.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://xjuxly.com/img/34235130.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://xjuxly.com/img/34235130.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://xjuxly.com/img/34235130.png"} />
			<meta name={"msapplication-TileImage"} content={"https://xjuxly.com/img/34235130.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" quarkly-title="FAQ-4">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--dark">
			FAQ | Часто задаваемые вопросы
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#111" sm-margin="0px 0px 50px 0px">
			Пока вы готовитесь доверить нам заботу о своем автомобиле, мы собрали ответы на некоторые часто задаваемые вопросы, чтобы ваш опыт работы в АвтоПульс был таким же гладким и надежным, как и ваша поездка.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="50px 50px"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--dark">
					Какие услуги предлагает мастерская "АвтоПульс"?

					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#111">
					АвтоПульс предоставляет полный спектр услуг, включая плановое техническое обслуживание, современный ремонт, экологичные решения и индивидуальные модификации. Наша цель - обеспечить наилучшие эксплуатационные характеристики вашего автомобиля.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--dark">
					Как узнать, что мой автомобиль нуждается в обслуживании?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#111">
					Обратите внимание на любые изменения в работе, необычные звуки или предупреждающие сигналы на приборной панели. Для поддержания оптимальной производительности и безопасности рекомендуется регулярно проходить техосмотр.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--dark">
					Используете ли вы для ремонта оригинальные детали?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#111">
					Да, мы отдаем предпочтение использованию оригинальных деталей производителя, чтобы обеспечить высочайшее качество и совместимость с вашим автомобилем. Альтернативные варианты доступны по запросу.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--dark">
					Может ли мастерская АвтоПульс работать с высокопроизводительными или роскошными автомобилями?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#111">
					Конечно! Наши техники умеют обслуживать широкий спектр автомобилей, включая высокопроизводительные и роскошные модели, гарантируя каждому из них специализированный уход.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--dark">
					Что делает "АвтоПульс" экологически чистым?

					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#111">
					Мы придерживаемся принципа экологичности и предлагаем такие услуги, как переработка деталей и жидкостей, а также используем экологически чистые продукты, стремясь минимизировать воздействие на окружающую среду.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--dark">
					Сколько времени занимает типичное обслуживание?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#111">
					Сроки обслуживания зависят от характера работ. Мы стараемся завершить рутинное обслуживание в течение дня. Для более сложных ремонтов мы предоставим оценку времени после оценки.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Contacts />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});